import { Map } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
import { toHuman } from 'lib/firebaseError';
import View from './View';


class VersionEditController extends BasePopupController {
  static View = View;
  position = new Map({ width: 400 });

  constructor(args, manager) {
    super(args, manager);
    const version = this.version = args.version;

    this.state = this.state.merge({
      label: version.get('label'),
      notes: version.get('notes'),
      isPublic: version.get('isPublic')
    });
  }

  async save() {
    const version = this.args.version;
    this.setState({ loading: true, alert: '' });
    try {
      await this.args.provider.updateVersion({
        articleId: version.get('articleId'),
        versionId: version.get('id'),
        data: {
          label: this.state.get('label'),
          notes: this.state.get('notes'),
          isPublic: this.state.get('isPublic')
        }
      });
      this.close();
    } catch(e) {
      console.error(e);
      this.setState({ alert: toHuman(e) });
    }
    this.setState({ loading: false });
  }
}

export default VersionEditController;