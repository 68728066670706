import { TextInput, Checkbox } from 'lib/inputs/basic';
import { Buttons, Button } from 'lib/inputs/buttons';
import Icon from 'lib/Icon';
import { Link2Icon } from '@radix-ui/react-icons';
import { faLaptopCode } from '@fortawesome/free-solid-svg-icons';

import './Auth.css';


function Auth({ popup }) {
  const
    { machine } = popup,
    status = machine.state.get('status'),
    errorMessage = machine.state.get('errorMessage'),
    connecting = (status === 'connecting' || status === 'reconnecting');

  var statusMessage, errored;
  switch (status) {
    case 'failed':
      errored = true;
      if (errorMessage === 'incorrect password') {
        statusMessage = 'Incorrect username or password.';
      } else if (errorMessage === 'authentication error') {
        statusMessage = 'Cannot Authenticate User.';
      } else if (errorMessage === 'xhr poll error') {
        statusMessage = 'Cannot reach remote.';
      } else {
        statusMessage = 'Error establishing connection.';
      }
      break;
    case 'connecting': statusMessage = 'Connecting...'; break;
    case 'reconnecting': statusMessage = 'Reconnecting...'; break;
    case 'disconnected': statusMessage = 'Disconnected'; break;
    case 'authenticating': statusMessage = 'Authenticating'; break;
    default: statusMessage = '';
  }

  return (
    <div>
      <div className="flex gap-4">
        <div className="text-7xl px-4 py-4">
          <Icon faIcon={faLaptopCode} />
        </div>
        <AuthForm popup={popup} connecting={connecting} />
      </div>

      { statusMessage && (
        <div className={'text-center p-2 border border-rim font-mono text-sm' + (errored ? ' text-error-red' : '')}>
          { statusMessage }
        </div>
      ) }
    </div>
  )
}

function AuthForm({ popup, connecting }) {
  const { state } = popup;

  return (
    <form className="flex-grow" onSubmit={e => { e.preventDefault(); popup.connect(); }}>
      <TextInput
        label="URL"
        value={state.get('url')}
        onChange={v => popup.setState({ url: v })}
        isDisabled={connecting} />

      <TextInput
        label="Username"
        value={state.get('username')}
        onChange={v => {
          if (state.get('fromSavedAuth'))
            popup.forget();
          popup.setState({ username: v })
        }}
        isDisabled={connecting} />

      <TextInput
        label="Password"
        type="password"
        autoComplete="off"
        value={state.get('password')}
        onChange={v => {
          if (state.get('fromSavedAuth'))
            popup.forget();
          popup.setState({ password: v })
        }}
        isDisabled={connecting} />

       { state.get('fromSavedAuth') && (
         <div className="text-right">
           <span className="link-button" onClick={_ => { popup.forget(); popup.clear();} }>Forget</span>
         </div> 
       ) }

      <Checkbox
        label="Remember Credentials?"
        value={state.get('rememberAuth')}
        onChange={v => {
          if (!v)
            popup.forget();
          popup.setState({ rememberAuth: v })
        }}
        isDisabled={connecting} />

      <Buttons className="text-right">
        {connecting ? (
          <Button
            onPress={e => { e.preventDefault(); popup.closeConnection(); }}>
            Cancel Connection
          </Button>
        ) : (
          <Button type="submit">
            <Link2Icon className="inline" />&nbsp; Connect
          </Button>
        )}
      </Buttons>
    </form>
  );
}

export default Auth;