import { Map } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
import { toHuman } from 'lib/firebaseError';
import View from './View';


class PopupController extends BasePopupController {
  static View = View;
  position = new Map({ width: 350 });

  constructor(args, manager) {
    super(args, manager);
    this.uploadArticleFile = this.args.uploadArticleFile;
    this.state = this.state.merge({ at: 'select' });
    this.fetchLimits();
  }
  
  discard = () => {
    this.setState({ at: 'select', file: null, alert: null });
  }

  cancelUpload = () => {
    if (this._cancelUpload)
      this._cancelUpload();
    this.setState({ uploaded: false, uploading: false, cancelled: true, alert: null });
  }

  beginUpload = () => {
    const
      file = this.state.get('file'),
      filename = this.state.get('filename');
    
    this.setState({
      loading: true, uploading: true, uploaded: false,
      cancelled: false,
      uploadPercent: 0, alert: null
    });

    this._cancelUpload = this.uploadArticleFile(
      { filename, file },

      uploadPercent => this.setState({ uploadPercent }),

      error => {
        this._cancelUpload = null;
        console.error(error);
        this.setState({ alert: toHuman(error), uploading: false, loading: false });
      },
      
      _ => {
        this._cancelUpload = null;
        this.setState({ uploadPercent: 100, uploaded: true, loading: false });
      }
    );
  }

  fetchLimits = async () => {
    try {
      this.setState({
        domainPlan: await this.args.fetchDomainPlan(),
        tally: await this.args.fetchTally()
      });
      console.log(this.state.toJS())
    } catch (e) {
      console.error(e);
      this.setState({ alert: 'Error fetching limits.' });
    }
  }
}

export default PopupController;