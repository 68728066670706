import { Map } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
import { toHuman } from 'lib/firebaseError';
import View from './View';

// hard access endpoints


class PopupController extends BasePopupController {
  static View = View;
  position = new Map({ width: 400 });

  constructor(args, manager) {
    super(args, manager);
    this.state = this.state.merge({ folderPath: [''], done: false });
    this.loadDomains();
  }

  loadDomains() {
    const { auth, remoteProvider } = this.args.app;
    auth.fetchUserDomains()
      .then((userDomains) => Promise.all(
        userDomains.map(u => remoteProvider.fetchDomain({ domainId: u.id }))
      ))
      .then(domains => this.setState({ domains }))
  }

  get currentProvider() {
    return (
      this.state.get('folderPath')[0] === 'local' ?
        this.args.app.localProvider: 
        this.args.app.remoteProvider
    );
  }

  clone = async () => {
    this.setState({ loading: true });
    try {
      const
        { article, provider } = this.args,
        { title, slug, layout, settings, description } = article.toJS(),
        path = this.state.get('folderPath').join('\\'),
        elementList = await provider.fetchArticleElements(article.get('id')),
        { itemId } = await this.currentProvider.fetchPath(path),
        elementMap = {};

      elementList.forEach(e => elementMap[e.id] = e);

      await this.currentProvider.createArticle({
        parentId: itemId, title, slug, layout, settings, description, elements: elementMap
      });
      this.setState({ loading: false, done: true });
    } catch (e) {
      this.setState({ loading: false, alert: toHuman(e) });
    }
  }
}

export default PopupController;