import { fetchPath } from 'api/remote/calls/path';
import { getDownloadURL } from 'api/remote/calls/file';
import { fetchVersion } from 'api/remote/calls/article';


export class Cache {
  storage = {};
  get = (key) => this.storage[key];
  set = (key, value) => this.storage[key] = value;
  clear = (key) => delete this.storage[key];
}

class AsyncFunctionCacher {
  // Caches a function that returns promise, based on the argument
  // single argument, string
  _cache = {};
  _promises = {};

  constructor(fn) {
    this.fn = fn;
  }

  async executeCall(arg) {
    let key;
    if (typeof arg === 'string') {
      key = arg;
    } else if (typeof arg === 'object') {
      key = JSON.stringify(arg);
    } else {
      throw new Error('Invalid argument')
    }
    if (typeof this._cache[key] !== 'undefined')
      return this._cache[key];
    
    if (typeof this._promises[key] !== 'undefined')
      return this._promises[key];

    const promise = this.fn(arg);

    this._promises[key] = promise;
    const result = await promise;
    this._cache[key] = result;
    delete this._promises[key];

    return result;
  }

  clear() {
    this._cache = {}
  }

  keys() {
    return Object.keys(this._cache);
  }
}


export class RemoteCache {
  constructor() {
    const fetchContentCacher = new AsyncFunctionCacher(this.fetchContent);
    this.fetchContentCached = path => fetchContentCacher.executeCall(path);

    const fetchPathCacher = new AsyncFunctionCacher(this.fetchPath);
    this.fetchPathCached = path => fetchPathCacher.executeCall(path);

    const getDownloadURLCacher = new AsyncFunctionCacher(getDownloadURL);
    this.getDownloadURLCached = path => getDownloadURLCacher.executeCall(path);

    const fetchVersionCacher = new AsyncFunctionCacher(fetchVersion);
    this.fetchVersionCached = arg => fetchVersionCacher.executeCall(arg);

    this.clearAll = () => {
      fetchContentCacher.clear();
      fetchPathCacher.clear();
      getDownloadURLCacher.clear();
      fetchVersionCacher.clear();
    }

    this.getAllKeys = () => {
      return [
        ...fetchContentCacher.keys(),
        ...fetchPathCacher.keys(),
        ...getDownloadURLCacher.keys(),
        ...fetchVersionCacher.keys()
      ]
    }
  }

  fetchPath = (path) => fetchPath(path.replace(/\//g, '\\'));

  fetchContent = async (nrl) => {
    const
      { articleId, versionId, filename } = await this.fetchArticleVersionFilename(nrl),
      filePath = `article/${articleId}/version/${versionId}/file/${filename}`,
      url = await this.getDownloadURLCached(filePath),
      response = await fetch(url),
      text = await response.text();
    return text;
  }

  fetchArticleVersionFilename = async (nrl) => {
    const
      split = nrl.split(/[/:]/),
      filename = split.pop(),
      path = split.join('/'),
      pathData = await this.fetchPathCached(path);

    if (!pathData)
      throw new Error('Could not locate: "' + path + '" makes sure it\'s the proper path.');
    
    const { type, itemId, publishedVersionId, articleId } = pathData;

    if (type !== 'version' && type !== 'article')
      throw new Error('The path is not to an article or a version: ' + nrl);
    
    if (type === 'article' && !publishedVersionId)
      throw new Error('The article is not pubished: ' + nrl);

    return ({
      articleId: type === 'article' ? itemId : articleId,
      versionId: type === 'version' ? itemId : publishedVersionId,
      filename
    })
  }

  getDownloadURL = async (nrl) => {
    const
      { articleId, versionId, filename } = await this.fetchArticleVersionFilename(nrl),
      version = await this.fetchVersionCached({ articleId, versionId });

    if (!version)
      throw new Error('Could not locate version: ', nrl);

    const { uploads } = version;
    for (let id in uploads) {
      if (uploads[id].filename === filename) {
        const url = await this.getDownloadURLCached(uploads[id].name);
        return url;
      }
    }
    throw new Error('File not found: ', nrl);
  }
}