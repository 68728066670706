import {
  collection, query, doc, onSnapshot, where, updateDoc, getDoc, getDocs
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { db, functions } from '../firebase';


export function onFolder(folderId, cb, ecb) {
  const ref = doc(db, 'folders', folderId)
  return onSnapshot(ref, snap => cb({ id: snap.id, ...snap.data() }), ecb);
}

const toObject = (qss) => qss.docs.map(doc => ({ id: doc.id, ...doc.data() }));

export function onChildrenFolders(folderId, cb, ecb) {
  const q = query(collection(db, 'folders'), where('parentId', '==', folderId))
  return onSnapshot(q, qss => cb(toObject(qss)), ecb);
}

export function onChildrenArticles(folderId, cb, ecb) {
  const q = query(collection(db, 'articles'), where('parentId', '==', folderId));
  return onSnapshot(q, qss => cb(toObject(qss)), ecb)
}

export async function createFolder({ parentId, title, slug }) {
  return await httpsCallable(functions, 'createFolderCall')({ parentId, slug, title });
}

export async function createArticle({ parentId, title, slug, layout, elements }) {
  return await httpsCallable(functions, 'createArticleCall')({ parentId, slug, title, layout, elements });
}

export async function updateFolder({ folderId, data }) {
  return await updateDoc(doc(db, 'folders', folderId), data);
}

export async function deleteArticle(articleId) {
  return await httpsCallable(functions, 'deleteArticleCall')({ articleId })
}

export async function deleteFolder(folderId) {
  return await await httpsCallable(functions, 'deleteFolderCall')({ folderId })
}

export async function renameSlug({ slug, versionId, articleId, folderId, domainId }) {
  return await await httpsCallable(functions, 'renameSlugCall')({
    slug, versionId, articleId, folderId, domainId
  });
}

export async function moveToFolder({ itemId, itemType, newParentId }) {
  return await httpsCallable(functions, 'moveToFolderCall')({ itemId, itemType, newParentId });
}

export async function fetchFolder({ folderId }) {
  const snap = await getDoc(doc(db, 'folders', folderId));
  return (snap.exists() ? ({ id: snap.id, ...snap.data() }) : null);
}

export async function fetchChildrenFolders({ folderId, isPublic }) {
  let q = query(collection(db, 'folders'), where('parentId', '==', folderId));
  if (isPublic)
    q = query(q, where('isPublic', '==', true));
  return await getDocs(q).then(qss => toObject(qss));
}

export async function fetchChildrenArticles({ folderId, isPublic }) {
  let q = query(collection(db, 'articles'), where('parentId', '==', folderId));
  if (isPublic)
    q = query(q, where('publishedVersionId', '!=', null));
  return await getDocs(q).then(qss => toObject(qss));
}