import { useEffect, useRef } from 'react';
import { excalidrawUrl } from 'config';
import clsx from 'clsx';
// import Icon from 'lib/Icon';
// import { faSave } from '@fortawesome/free-solid-svg-icons';

export default function Excalidraw({ controller }) {
  const ref = useRef();

  useEffect(_ => {
    if (!ref.current)
      return;

    const
      excaliWindow = ref.current.contentWindow,
      handleMessage = event => controller.handleMessage(event);

    controller.setRemoteWindow(excaliWindow);
    window.addEventListener('message', handleMessage);
    return _ => window.removeEventListener('message', handleMessage);
  }, [controller]);

  return (
    <div className="excalidraw-iframe-container fixed top-0 left-0 w-full h-full bg-paper z-50">
      <iframe
        className="w-full h-full border-none"
        src={excalidrawUrl}
        name="_excalidraw"
        title="_excalidraw"
        ref={ref}/>

{/*      { controller.state.get('ready') && (
        <div className="overlay-buttons">
          <div
            className="overlay-button"
            onClick={_ => controller.handleClickSave()}>
            <span className="icon"><Icon type="Save" /></span> &nbsp;Save
          </div>

          <div
            className="overlay-button"
            onClick={_ => controller.handleClickCancel()}>
            <span className="icon"><Icon type="ArrowRightFromBracket" /></span> &nbsp;Close
          </div>
        </div>
      ) }
*/}
      <Note
        className="text-green-800 bg-green-100"
        show={controller.state.get('showSavedMessage')}>
        Saved!
      </Note>

      <Note
        className="text-red-800 bg-red-100"
        show={controller.state.get('showErrorMessage')}>
        <strong>Error:</strong> { controller.state.get('errorMessage') }
      </Note>
    </div>
  );
}

function Note({ children, className, show  }) {
  return (
    <div
      className={clsx(
        'top-[4rem] right-[1rem] absolute p-4',
        'transition-opacity pointer-events-none',
        className,
        show ? 'opacity-100' : 'opacity-0'
      )}>
      { children }
    </div>
  )  
}