import BaseController from 'lib/BaseController';
import elements from 'modules/elements/all';
import { debounce } from 'lodash';
import { Map } from 'immutable';

const AUTOSAVE_DELAY = 2000;


class ElementManager extends BaseController {
  constructor(data, article) {
    super();
    const { articleId, type, version, id } = data;
    this.article = article;
    this.id = id;
    this.articleId = articleId;
    this.type = type;
    this.lib = elements[type];

    if (!this.lib) {
      console.log('For element data', data);
      throw new Error('Type not found - ' + type);
    }

    this.controlState = new Map({ localVersion: version || 0 });
    this.state = this.lib.Policies.dataToState(data, null);
    this.remoteState = this.state;

    this.parentId = data.parentId;
    
    this.controller = new this.lib.Controller({
      elementId: this.id,
      getState: () => this.state,
      setState: this.setState,
      onStateChange: cb => this.on('change', cb),      
      getVersion: () => this.version,
      docId: this.article.id,
      docPath: this.article.path,
      themeData: this.article.domain.themeData,
      getLocalFiles: () => this.article.localFiles.proxy,
      getRemoteFiles: () => this.article.remoteFiles.proxy,
      getMachineInterface: () => this.article.app.machine.interface.getProxy({
        elementId: this.id,
        docId: this.article.id,
        docPath: this.article.path,
        getLocalFiles: () => this.article.localFiles.proxy
      }),
      isReadOnly: false,
      save: () => this.saveFromState(),
      activate: () => this.setActive(),
      openPopup: this.article.popups.openPopup,
      popOff: this.popOff,
      vani: this.article.app.vani,
      
      deleteSelf: () => this.article.deleteElement(this.id),
      addChildFilePopup: ({ onCreate, isPulled }) => this.article.popups.addChildFile({
        parentId: this.id,
        onCreate,
        isPulled
      }),
      getChildren: () => this.article.getElementChildren(this.id).map(element => element.controller),
      onChildren: cb => this.on('children', cb),
      commonEmitter: this.article.commonEmitter,
      getColorScheme: () => this.article.app.colorScheme.getSchemeValue()
    });
  }

  canAutosave() {
    return true;
  }

  setActive() {
    this.article.selection.setActiveElementId(this.id);
  }

  // state handlers
  handleData(data) {
    const remoteVersion = data.version;
    if (this.controlState.get('localVersion') < remoteVersion) {
      this.state = this.lib.Policies.dataToState(data, this.state);
      this.remoteState = this.state;
      this.setControlState({ localVersion: remoteVersion, modified: false });
      this.emit('change');
    }
  }

  setState = (object, silent) => {
    const oldState = this.state;
    this.state = oldState.merge(Map(object));
    if (silent) { return; }
    const changed = this.lib.Policies.contentHasChanged(oldState, this.state);
    this.emit('change');

    if (changed) {
      const savePending = this.lib.Policies.autoSave && this.canAutosave();
      this.setControlState({ modified: true, savePending });
      this.setUnsavedNofification(true);
      if (savePending) {
        this.autoSaveFromState();
      } 
    }
  }

  setControlState = (change) => {
    this.controlState = this.controlState.merge(change);
    this.emit('control-change');
  }

  get version() {
    return this.controlState.get('localVersion') + !!this.controlState.get('modified');
  }

  saveFromState = async () => {
    if (!this.controlState.get('modified'))
      return;

    console.log('saving...');
    this.setControlState({
      saving: true,
      modified: false,
      savePending: false,
      localVersion: this.controlState.get('localVersion') + 1
    });
    this.setUnsavedNofification(false);
    
    var data;
    if (this.lib.Policies.getDataUpdates) {
      data = this.lib.Policies.getDataUpdates(this.state, this.remoteState);
    } else {
      data = this.lib.Policies.stateToData(this.state);
    }
    data.version = this.controlState.get('localVersion');

    try {
      await this.updateData(data);
      this.remoteState = this.state;
    } catch (e) {
      console.error(e);
      this.setControlState({ saveError: e });
    }
    this.setControlState({ saving: false });
  }

  updateData = async (data) => {
    return this.article.provider.updateElement({
      articleId: this.article.id,
      elementId: this.id,
      data: data
    });
  }

  autoSaveFromState = debounce(this.saveFromState, AUTOSAVE_DELAY);

  destroy() {
    console.log('destroying element', this.type);
    this.controller.destroy();
    this.setUnsavedNofification(false);
    super.destroy();
  }

  // Helper functions
  get elementFilenames() { return this.controller.elementFilenames; }
  getElementFile(filename) { return this.controller.getElementFile(filename); }

  setUnsavedNofification(s) {
    if (this.goingToNotify === s) return;
    this.goingToNotify = s;
    window[s ? 'addEventListener' : 'removeEventListener']('beforeunload', this.notifyBeforeUnload);
  }

  notifyBeforeUnload(e) {
    e.preventDefault();
    e.returnValue = 'You have unsaved changes.';
    return 'You have unsaved changes.';
  }

  // Focus Imperative
  focus = () => {
    if (this.controller && this.controller.focus) {
      this.controller.focus();
    } else {
      this.article.selection.focusPage();
      this.article.selection.blurPage();
      this.setActive();
    }
  }

  scrollIntoView = () => {
    if (this._wrapperEl) {
      if (this._wrapperEl.getBoundingClientRect().bottom > window.innerHeight) {
          this._wrapperEl.scrollIntoView(false);
      } else if (this._wrapperEl.getBoundingClientRect().top < 80) {
          this._wrapperEl.scrollIntoView();
      }
    }
  }

  setWrapperEl = (el) => {
    this._wrapperEl = el;
  }

  popOff = () => {
    this.extPopup = this.article.popups.popOffElement({
      element: this,
      onClose: _ => {
        this.setState({ poppedOff: false });
        this.extPopup = null;
      }
    });
    this.setControlState({ poppedOff: true });
  }
}

export default ElementManager;