import { fromJS, Map } from 'immutable';
import { toHuman } from 'lib/firebaseError';
import BasePopup from 'lib/popup/BasePopupController';
import View from './View';

import RenameSlug from 'modules/folder/popups/RenameSlug/Controller';
import VersionCreate from '../VersionCreate/Controller';
import VersionDelete from '../VersionDelete/Controller';
import VersionPublish from '../VersionPublish/Controller';
import VersionRevert from '../VersionRevert/Controller';
import VersionEdit from '../VersionEdit/Controller';



class Popup extends BasePopup {
  static View = View;
  position = new Map({ width: 420 });

  constructor(args, manager) {
    super(args, manager);
    this.articleController = args.articleController;
    this.provider = this.articleController.provider;
    this.state = this.state.merge({
      title: args.title || 'Manage Versions',
      selectedId: null
    });
    this.load();
  }

  load = () => {
    const articleId = this.articleController.id;
    const dc = this.provider.onArticleVersions(
      articleId,
      versions => this.setState({ versions: fromJS(versions) }),
      error => this.setState({ alert: toHuman(error) })
    );
    this.unloadFns.push(_ => dc());
  }

  updateVersion = ({ versionId, data }) => {
    const
      articleId = this.articleController.id,
      versions = this.state.get('versions'),
      index = this.state.get('versions').findIndex(v => v.get('id') === versionId),
      version = versions.get(index);

    const newData = { label: version.get('label'), notes: version.get('notes') , ...data };
    
    console.log(newData);
    this.setState({ versions: versions.set(index,version.merge(data)) });
    this.provider.updateVersion({ versionId, articleId, data: newData })
      .catch(error => {
        console.error(error);
        this.setState({ alert: toHuman(error) })
      });
  }

  versionIsPublished(version) {
    const article = this.articleController.state.get('article');
    return version.get('id') === article.get('publishedVersionId')
  }

  openCreatePopup() {
    this.manager.open(VersionCreate, { articleController: this.articleController })
  }

  openRenameSlugPopup({ version }) {
    this.manager.open(RenameSlug, { version, provider: this.provider });
  }

  openPublishPopup({ version }) {
    this.manager.open(VersionPublish, {
      version,
      provider: this.provider,
      isPublished: this.versionIsPublished(version) });
  }

  openRevertPopup({ version }) {
    this.manager.open(VersionRevert, { version, provider: this.provider });
  }

  openEditPopup({ version }) {
    this.manager.open(VersionEdit, {
      version,
      provider: this.provider,
      isPublished: this.versionIsPublished(version) });
  }

  openDeletePopup({ version }) {
    this.manager.open(VersionDelete, {
      version,
      provider: this.provider,
      isPublished: this.versionIsPublished(version) });
  }
}

export default Popup;