import { useState, useEffect } from 'react';
import Loader from 'lib/staticPages/Loader';
import clsx from 'clsx';
import { LinkBreak1Icon, ImageIcon } from '@radix-ui/react-icons';


export default function Entry({ entry, controller, show }) {
  const
    filename = entry.get('filename') || '',
    [url, setUrl] = useState(null),
    [status, setStatus] = useState('loading'),
    [contentType, setContentType] = useState(null),
    noImage = (status === 'load-error' || status === 'not-found' || status === 'empty' || status === 'loading'),
    // updatedAt = controller.state.get('uploadsUpdatedAt'),
    backgroundColor = (
      status === 'loaded' &&
      (contentType.startsWith('image') || contentType.startsWith('video')) &&
      controller.getSettingsValue('fillerColor')
    ) || 'inherit';

  useEffect(() => {
    function loadFileImage(filename) {
      if (!filename) {
        setStatus('empty');
        return;
      }
      const file = controller.getLocalFiles().getFile(filename);
      
      if (file) {
        setStatus('loading');
        file.getURL()
          .then(url => {
            setUrl(url);
            setContentType(file.contentType);
            setStatus('loaded'); })
          .catch(e => setStatus('load-error'));
      } else {
        setStatus('not-found');
      }
    }

    loadFileImage(filename);
    return controller.commonEmitter.on(`file update ${filename}`, () => loadFileImage(filename));
  }, [filename, controller]);

  return (
    <div
      className={clsx(
        'entry absolute h-full w-full top-0 left-0 bg-center bg-cover flex',
        'justify-center items-center fade-effect',
        !show && 'hidden',
        noImage && 'bg-striped'
      )}
      style={{ backgroundColor }}>
      { noImage && (
        <div className="text-pencil2 text-center">
          { status === 'loading' && (<Loader className="text-2xl" />)}
          { status === 'load-error' && (<div className="font-medium text-red-800">Media Load Error</div>)}
          { status === 'not-found' && (<>
            <LinkBreak1Icon className="inline-block w-8 h-8 mb-2" />
            <div>File Not Found</div>
          </> )}
          { status === 'empty' && !controller.isReadOnly && (<>
            <ImageIcon  className="inline-block w-6 h-6 mb-2" />
            <div
              className="cursor-pointer underline"
              onClick={_ => controller.selectImagePopup() }>
              Select an Image
            </div>
          </>)}
          { status === 'empty' && controller.isReadOnly && (<>
            <ImageIcon  className="inline-block w-6 h-6 mb-2" />
            <div>Image not selected</div>
          </>)}
        </div>
      )}

      { status === 'loaded' && (
        <Media url={url} contentType={contentType} controller={controller} />
      ) }
    </div>
  )
}

function Media({ url, contentType, controller }) {
  const { getSettingsValue } = controller;

  return (
    <>
      { contentType.startsWith('video') && (
        <video controls className="h-full w-full">
          <source src={url} type={contentType} />
        </video>
      ) }

      { contentType.startsWith('audio') && (
        <div className="h-full w-full flex justify-center items-center">
          <audio controls className="w-full">
            <source src={url} type={contentType} />
          </audio>          
        </div>
      )}

      { contentType.startsWith('image') && (
        <div
          className="h-full w-full absolute left-0 top-0 bg-center"
          style={{
            backgroundImage: `url(${url})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: getSettingsValue('fitting')
          }}></div>
      )}
    </>
  )
}