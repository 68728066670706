export default class File {
  constructor({
    filename, contentType, version, getContent,
    isBase64, isPulled, location, getDownloadURL, docPath
  }) {
    this.filename = filename;
    this.contentType = contentType;
    this.version = version;
    this.getContent = getContent;
    this.isBase64 = isBase64;
    this.isPulled = isPulled;
    this.location = location;
    this.getDownloadURL = getDownloadURL;
    this.docPath = docPath;
  }

  get ext() {
    const match = this.filename.match(/\.[0-9a-z]+$/i);
    return match && match[0];
  }

  get isScript() {
    const { ext } = this;
    return (ext === '.js' || ext === '.jsx' ||ext === '.ts' || ext === '.tsx');
  }

  get isCSS() {
    const { ext } = this;
    return (ext === '.css');
  }

  get isDocFile() {
    return !this.location;
  }

  async getURL() {
    if (this.location) {
      return await this.getDownloadURL(this.location);
    }
    if (this.getContent) {
      let
        content = await this.getContent(),
        es = this.isBase64 ? ';base64': '',
        cs = this.isBase64 ? content : encodeURIComponent('content');

      // todo: check if needs encode.
      return `data:${this.contentType}${es},${cs}`
    }
  }

  toObject = async (forMachine) => {
    let
      { filename, contentType, location, isBase64 } = this,
      content, url;

    if (location)
      url = await this.getDownloadURL(location, forMachine);
    else
      content = await this.getContent();

    return ({ filename, contentType, url, content, location, isBase64 });
  }  
}
