import { useEffect } from 'react';
import { useControllerState } from 'lib/BaseController';
import ItemList from 'modules/folder/views/List';
import AddMenu from 'modules/folder/views/AddMenu';
import { Set } from 'immutable';
import { PlusIcon } from '@radix-ui/react-icons';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';


export default function Loader({ editor }) {
  const
    articleController = editor.currentArticleController,
    state = useControllerState(articleController),
    folderId = state.getIn(['article', 'parentId']);

  return (folderId && (
    <FolderPanel
      editor={editor}
      folderController={editor.folderController}
      folderId={folderId}
      provider={articleController.provider} />
  ));
}


function FolderPanel({ editor, folderController, folderId, provider }) {
  const
    state = useControllerState(folderController),
    navigate = useNavigate();

  useEffect(() => {
    if (folderId)
      folderController.load({ id: folderId, provider: provider });
  }, [folderId, folderController, provider]);

  return folderController.isLoaded && (
    <div className="nb-folder-panel flex flex-col h-full w-full overflow-hidden">
      
      <div className={clsx('title-container py-3 px-5 border-b border-rim')}>
        <div className="title font-medium">{ state.getIn(['self', 'title']) }</div>
        <div className="font-mono text-xs">{state.getIn(['self', 'slug'])}</div>
      </div>

      <div className="items-container slim-sidebar py-2 px-1 flex-grow border-b border-rim overflow-auto">
        <ItemList
          activeId={editor.currentArticleId}
          openIds={new Set(editor.state.get('articleIds'))}
          controller={folderController}
          onPressClose={(id) => editor.closeArticle(id, navigate)}
          compact={true} />
      </div>
      <div className="p-2 text-right">
        <AddMenu controller={folderController} className="p-1">
          <PlusIcon className="w-5 h-5" />
        </AddMenu>
      </div>
    </div>
  )
}