import { Map } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
import { toHuman } from 'lib/firebaseError';
import View from './View';


class AddNewFolderController extends BasePopupController {
  static View = View;
  position = new Map({ width: 300 });

  constructor(args, manager) {
    super(args, manager);
    this.fetchPath = args.provider.fetchPath;
    this.createFolder = args.provider.createFolder;
    this.checkAvailable = args.provider.checkAvailable;

    this.state = this.state.merge({
      parentPath: args.parentPath,
      title: '',
      slug: '',
      autoSlug: true
    });
  }

  async addFolder() {
    this.setState({ loading: true, alert: '' });
    try {
      const
        parentPath = this.state.get('parentPath'),
        parentId = (await this.fetchPath(parentPath)).itemId,
        title = this.state.get('title'),
        slug = this.state.get('slug');
      await this.createFolder({ slug, title, parentId });
      this.setState({ loading: false });
      this.close();
    } catch(e) {
      console.error(e);
      this.setState({ alert: toHuman(e), loading: false });
    }
  }
}

export default AddNewFolderController;