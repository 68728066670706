import React from 'react';
import { ui } from 'lib/popup';
import { Buttons, Button } from 'lib/inputs/buttons';
import { useControllerState } from 'lib/BaseController';
import { FileTextIcon } from '@radix-ui/react-icons';

import './style.css';


function View({ popup }) {
  const
    state = useControllerState(popup),
    loading = state.get('loading'),
    upload = popup.args.upload;

  return (
    <ui.Popup popup={popup} className="delete-file-popup">
      <ui.Dialog>
        <ui.TitleBar>
          <ui.Title>
            Delete File
          </ui.Title>
          <ui.CloseButton />
        </ui.TitleBar>
        <ui.Body>
          <form onSubmit={e => { e.preventDefault(); popup.deleteUpload(); }}>
            <ui.Warning title="Delete this file?">
              {
                !state.get('usedBy') ? '...' :
                state.get('usedBy').length === 0 ? ( <>
                  You are about to delete this file completely. <br />
                  Are you sure you want to proceed?
                </> ) : ( <>
                  This file won't be deleted from versions
                  that uses it,unless you delete those versions.
                </> )
              }
            </ui.Warning>

            <div className="filename font-mono py-2 px-5 text-sm bg-wax2">
              <FileTextIcon className="inline-block" /> { upload.get('filename') }
            </div>

            <ui.Alert />

            <Buttons className="text-right">
              <Button isDisabled={loading} type="submit">Delete</Button>
              <Button onPress={e => { popup.close() }}>Cancel</Button>
            </Buttons>
          </form>
        </ui.Body>
      </ui.Dialog>
    </ui.Popup>
  );
}

export default View;