import { useState, useEffect } from 'react';

export default function AuthFlash({ app }) {
  const
    { auth } = app,
    userId = auth.user && auth.user.uid,
    [current, setCurrent] = useState(userId),
    [show, setShow] = useState(false);

  useEffect(() => {
    if (current === userId)
      return;
    setCurrent(userId);
    setShow(true);
    setTimeout(() => setShow(false), 2000);
  }, [userId, current]);

  return show && (
    <div className="right-6 top-14 fixed z-50 text-pencil2 bg-paper p-1 px-2">
      {current ? 'Logged In' : 'Signed Out'}
    </div>
  );
}