import p from 'lib/popup/components/ui';
import { Group } from 'lib/inputs/basic';
import { Buttons, Button } from 'lib/inputs/buttons';
import { useControllerState } from 'lib/BaseController';
import PathSelector from 'lib/inputs/PathSelector';

import './style.css';


function Popup({ popup }) {
  let
    { article } = popup.args,
    state = useControllerState(popup);

  return (
    <p.Popup popup={popup} className="clone-article-popup">
      <p.Dialog>
        <p.TitleBar>
          <p.Title>
            Clone Article
          </p.Title>
          <p.CloseButton />
        </p.TitleBar>
        <p.Body>
          {state.get('done') && (<>
            <div className="">
              <div className="font-medium m-3">The article</div>

              <p.Item
                itemType="Article"
                title={article.get('title')}
                slug={article.get('slug')} />

              <div className="font-medium m-3">
                has been cloned to:
                <div className="font-mono text-sm mt-2">{state.get('folderPath').join('/')}</div>
              </div>


              <Buttons className="text-right">
                <Button onPress={popup.close}>
                  Ok
                </Button>
              </Buttons>
            </div>
          </>)}
          {!state.get('done') && (
            <Form popup={popup} />
          )}
        </p.Body>
      </p.Dialog>
    </p.Popup>
  )
}

function Form({ popup }) {
  const
    { state, args } = popup,
    { article } = args,
    loading = state.get('loading'),
    domains = state.get('domains');

  return (
    <form onSubmit={e => { e.preventDefault(); popup.clone(); }}>
      <Group>
        <p.Info title="Note for cloning an article">
          This will <strong>NOT</strong> copy over the
          article's <strong>uploaded files</strong> or the article's <strong>versions</strong>.
        </p.Info>

        <div className="font-medium text-sm mb-2">Article</div>
        <p.Item
          itemType="Article"
          title={article.get('title')}
          slug={article.get('slug')} />

        <div className="font-medium text-sm mb-2">Clone To</div>
        <select
          className="border border-rim2 px-2 p-1"
          value={state.get('selectedDomain')}
          onChange={e => popup.setState({ folderPath: [e.currentTarget.value] })}>
          <option value="">Select Workspace</option>
          <option disabled={true} value="">---</option>
          <option value="local">Offline Workspace</option>
          <option disabled={true} value="">---</option>
          { !domains && (
            <option disabled={true} value="">loading ...</option>
          ) }
          { domains && domains.map((domain, i) => (
            <option key={i} value={domain.slug}>{domain.title}</option>
          )) }
        </select>

      { state.get('folderPath')[0] && (
        <PathSelector
          label="Folder"
          value={state.get('folderPath')}
          omitIds={[article.get('parentId')]}
          isDisabled={loading}
          fetchPath={popup.currentProvider.fetchPath}
          fetchChildrenFolders={popup.currentProvider.fetchChildrenFolders}
          onChange={v => popup.setState({ folderPath: v })} />
      ) }
      </Group>

      <p.Alert />

      <Buttons className="text-right">
        <Button type="submit" isDisabled={loading || !(state.get('folderPath')[0])}>
          Clone
        </Button>
        <Button isDisabled={loading} onClick={_ => popup.close()}>
          Cancel
        </Button>
      </Buttons>
    </form>
  )
}

export default Popup;