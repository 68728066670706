import { fromJS } from 'immutable';
import Grid, { GRID_DEFAULTS } from './Grid';
import History from './History';
import Scroll from './Scroll';

class Layout {
  constructor(article) {

    this.getState = () => article.state.getIn(['article', 'layout']);
    this.setState_ = o => article.setInState(
      ['article', 'layout'],
      this.getState().merge(o));
    this.save = () => article.saveArticleStateKey('layout');
    this.newElementPopup = article.popups.newElementPopup;
    this.openPopup = article.popups.openPopup;
    this.selection = article.selection;
    this.getId = () => article.id;
    this.themeData = article.domain.themeData;

    this.elementContainerRefs = {};
    this.grid = new Grid(this);
    this.scroll = new Scroll(this);
    this.history = new History(this.state);
  }

  get state() { return this.getState(); }
  get order() { return this.state && this.state.get('order'); }
  get type() { return this.state && this.state.get('type'); }
  get fontSize() { return Number(this.themeData.getValue('fontSize')); }
  get pointSize() { return (this.fontSize / 4); }

  setElementContainerRef = (id, ref) => { this.elementContainerRefs[id] = ref; };
  getElementContainerRef = (id) => this.elementContainerRefs[id];

  getElementIndex = (elementId) => this.order.indexOf(elementId);
  getElementIdAt = (index) => this.order.get(index);
  setOrder = (order) => this.setState({ order });

  moveElementIndex(sourceIndex, destinationIndex) {
    if (sourceIndex < 0 || destinationIndex < 0 ||
      destinationIndex > this.order.size - 1 || sourceIndex > this.order.size - 1
    ) {
      return;
    }
    this.setOrder(
      this.order
        .splice(sourceIndex, 1)
        .splice(destinationIndex, 0, this.order.get(sourceIndex))
    );
    this.save();
  }

  addElementId(id, index) {
    const
      { order } = this,
      placeIndex = (index >= 0 && index <= order.size) ? index : order.size;
    this.setOrder(order.splice(placeIndex, 0, id));
    this.save();
  }

  deleteElementId(id) {
    var
      order = this.state.get('order'),
      elements = this.state.getIn(['grid', 'elements']),
      state = this.state;

    order = order.filter(oid => id !== oid);
    state = this.state.set('order', order);

    if (elements) {
      elements = elements.filter((v, eid) => eid !== id);
      state = state.setIn(['grid', 'elements'], elements);
    }
    this.setState(state);
    this.save();
  }

  switchType(type) {
    this.openPopup('prompt', {
      title: 'Change Layout',
      question: 'Are you sure you want to change layout? You will lose current layout data.',
      submitLabel: 'Yes',
      onSubmit: () => {
        const grid = type === 'grid' ? fromJS(this.getConvertedGrid()) : null;
        this.setState({ type, grid });
        this.save();
      }
    });
  }

  getConvertedGrid = () => {
    var elements = {}, y = 0, x = 0, w = 12, h;

    // todo : grab the width and height, and use that instead.
    this.order.forEach((id, i) => {
      const el = this.getElementContainerRef(id);
      h =  el ? Math.max(1, Math.ceil(el.getBoundingClientRect().height / GRID_DEFAULTS.cellSize.height)) : 2;
      elements[id] = ({ id, x, y, w, h });
      y = y + h;
    });

    return ({
      config: {
        cellSize: null,
        cellGap: null
      },
      elements
    });
  }

  handleEscapeKey() {
    if (this.type === 'grid') {
      if (this.grid.state.get('cursor') !== 'default') {
        this.grid.setState({ cursor: 'default' });
        return true;
      }
    } else {
      if (this.scroll.state.get('adding') === true) {
        this.scroll.setState({ adding: false });
        return true;
      }
    }
  }

  setState(obj) {
    this.history.push(this.state);
    this.setState_(obj);
  }

  undo() {
    var prev = this.history.undo(this.state);
    if (!prev)
      return;
    this.setState_(prev);
    this.save();
  }

  redo() {
    var next = this.history.redo(this.state);
    if (!next)
      return;
    this.setState_(next);
    this.save();
  }
}

export default Layout;