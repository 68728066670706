import React from 'react';
import { Switch as AriaSwitch, GridList, GridListItem } from 'react-aria-components';
import {RadioGroup, Radio} from 'react-aria-components';

import BaseSelection from './BaseSelection';
import clsx from 'clsx';

import './basic.css';

export { TextInput, TextAreaInput } from './texts';
export { Checkbox } from './checkboxes';


export function NativeSelection({ label, value, options, onChange }) {
  return (
    <label className="my-3 block">
      <Label>{label}</Label>
      <div className="selection-container">
        <select
          className="border border-rim2 pl-2 pr-5 p-1 w-full"
          value={value}
          onChange={e => onChange(e.target.value)}>
          {options.map(([a, b]) => (<option value={a} key={a}>{b}</option>))}
        </select>
      </div>
    </label>
  );
}


export function Group({ children }) {
  return (<div className="">{children}</div>);
}


export function CardSelect({ label, options, align, onChange, value, itemClassName, listClassName }) {
  return (
    <div className="my-3">
      <Label>{label}</Label>
      <GridList
        className={clsx(listClassName || 'flex gap-2 border p-2 bg-wax', align && 'justify-center')}
        aria-label="Collection List"
        selectionMode="single"
        selectedKeys={new Set([value])}
        items={options}
        onSelectionChange={keys => { if (typeof keys.currentKey !== 'undefined') onChange(keys.currentKey); }}>
        {({ Card, value, textValue }) => (
          <GridListItem
            id={value}
            textValue={textValue || value}
            className={({isSelected}) => clsx(
              'border cursor-pointer rounded-sm bg-paper',
              itemClassName,
              isSelected ? 'border-marker' : 'border-rim3'
            )}>
            <Card />
          </GridListItem>
        )}
      </GridList>
    </div>
  );
}

export function Switch({ label, value, onChange, isDisabled, defaultValue }) {
  return (
    <AriaSwitch
      className={clsx(
        'group items-center  inline-block',
        !isDisabled && 'cursor-pointer'
      )}
      isSelected={value}
      onChange={e => { console.log(e); onChange(e); }}>
      <Label>{label}</Label>
      <div
        className={clsx(
          'h-5 w-10 rounded-full inline-block',
          value ? 'bg-marker' : 'bg-pencil3'
        )}>
        <div
          className={clsx(
            'h-4 w-4 mt-0.5 ml-0.5 transform rounded-full bg-paper transition duration-100 ease-in-out',
            value ? 'translate-x-5' : 'translate-x-0'
          )} />
      </div>
    </AriaSwitch>
  )
}


export function Selection({ label, options, value, onChange }) {
  return (
    <div className="my-3">
      <BaseSelection {...{
        label, options, value, onChange,
        LabelComponent: Label,
        buttonClassName: 'border border-rim2 bg-wax px-2 py-1 w-full ',
        listClassName: '',
        }} />
    </div>);
}

export function RadioSelect({ label, align, autoFocus, value, onChange, options, previews }) {
  const
    selected = options.find(o => o[0] === value),
    preview = selected && selected[2];

  return (
    <RadioGroup value={value} onChange={onChange} aria-label={label}>
      <Label>{label}</Label>
      <div className="flex">
        <div className="flex flex-col gap-1">
          {options.map(([key, caption]) => (
            <Radio key={key} value={key} className="flex gap-2 items-center font-medium text-sm">
              <div
                className={clsx(
                  value === key ? 'border-marker border-4' : ' border-rim2 border',
                  'w-3 h-3 rounded-full bg-wax3'
                )}></div>
              {caption}
            </Radio>
          ))}
        </div>

        { preview }
      </div>
    </RadioGroup>
  )
}

export function Label({ children, className }) {
  return (<div className={clsx('font-medium text-sm mb-2')}>{children}</div>)
}