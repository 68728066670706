import { LeftGroup, RightGroup } from './BarHorizontal';

export default function BarFloating({ editor }) {
  return (
    <div>
      <div className="absolute top-2 left-2 flex bg-paper border border-rim rounded-sm pl-4 pr-2 py-2 z-40">
        <LeftGroup editor={editor} />
      </div>

      <div className="absolute top-2 right-4 flex items-center bg-paper py-1 px-2 gap-1 border border-rim rounded-sm z-40">
        <RightGroup editor={editor} verticle={false} />
      </div>
    </div>
  )
}