import { Map } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
import { toHuman } from 'lib/firebaseError';
import View from './View';
import templates from './templates';
import { fetchTemplate } from 'api/public';

class PopupController extends BasePopupController {
  static View = View;
  position = new Map({ width: 340 });

  constructor(args, manager) {
    super(args, manager);
    this.fetchPath = args.provider.fetchPath;
    this.createArticle = args.provider.createArticle;
    this.checkAvailable = args.provider.checkAvailable;

    this.state = this.state.merge({
      parentPath: args.parentPath,
      title: '',
      slug: '',
      layoutType: 'grid',
      templateIndex: 0,
      autoSlug: true,
      created: false
    });
  }

  async addArticle() {
    this.setState({ loading: true, alert: '' });
    try {
      const
        parentPath = this.state.get('parentPath'),
        parentId = (await this.fetchPath(parentPath)).itemId,
        title = this.state.get('title'),
        slug = this.state.get('slug'),
        { elements, layout } = await this.fetchTemplate();

      await this.createArticle({ slug, title, layout, elements, parentId });
      this.setState({ loading: false, created: true });
    } catch(e) {
      console.error(e);
      this.setState({ alert: toHuman(e), loading: false });
    }
  }

  async fetchTemplate() {
    if (this.state.get('layoutType') !== 'template')
      return ({
        elements: [],
        layout: {
          type: this.state.get('layoutType'),
          order: [],
          grid: { config: {}, elements: {} }
        }
      });
    const templatePath = templates[this.state.get('templateIndex')][0];
    return await fetchTemplate(templatePath);
  }
}

export default PopupController;