import BaseController from 'lib/BaseController';
import { toHuman } from 'lib/firebaseError';


export default class Usage extends BaseController {
  constructor(domain) {
    super();
    this.domain = domain;
    this.state = this.state.merge({
      loading: false,
      subscription: null,
      tally: null,
      plans: null
    });
  }

  fetch = async () => {
    const { provider } = this.domain;
    this.setState({ isLoaded: false })
    try {
      const [subscription, plans, tally] = await Promise.all([
        provider.fetchSubscription({ domainId: this.domain.id }),
        provider.fetchDomainPlans({ domainId: this.domain.id }),
        provider.fetchTally({ domainId: this.domain.id })
      ]);
      this.setState({ subscription, tally, plans, loading: false, isLoaded: true });
     } catch (e) {
      console.error(e);
      this.setState({ alert: toHuman(e), loading: false });
    }
  }
}