import { useState } from 'react';
import startDragging from 'lib/startDragging';
import clsx from 'clsx';

export default function HeightResizer({ onChange, onDrag }) {
  const
    [dragging, setDragging] = useState(false),
    [dy, setDy] = useState(0);
  onDrag = onDrag || (() => {});
  return (
    <div
      className={clsx(
        'w-full absolute h-[6px] left-0 bg-black z-10',
        dragging ? 'opacity-30' : 'opacity-0 hover:opacity-20'
      )}
      style={{ bottom: -dy - 3, cursor: 'ns-resize' }}
      onMouseDown={(e) => {
        setDragging(true);
        onDrag(true);
        startDragging({
          event: e,
          onMove: (e, dx, dy) => { setDy(dy); },
          onEnd: (e, dx, dy) => {
            onChange(dy);
            setDy(0);
            setDragging(false);
            onDrag(false);
          }
        });
      }} />);
}