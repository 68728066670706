import { ALL_THEME_OPTIONS } from 'modules/theme/defaults/code';
import { getBoolSelectOptions, getGetOptionsFnWithDefaults } from 'modules/elements/lib/helpers';


const settingsFields = {
  theme: {
    caption: 'Theme', input: 'options', init: null,
    options:  [
      { key: null, caption: 'Default / Auto' },
      { type: 'break' },
    ].concat(ALL_THEME_OPTIONS),
    themeKey: 'codeTheme'
  },

  autoHeight: {
    caption: 'Height', input: 'options', init: false,
    options: [
      { caption: 'Fixed', key: false },
      { caption: 'Auto', key: true }
    ]
  },

  height: {
    caption: (<>&nbsp;</>), input: 'number', init: 240,
    unit: 'px',
    range: [42, 700],
    isHiddenFn: getValue => getValue('autoHeight') === true },

  hasLineNumbers: {
    caption: 'Line Numbers', input: 'options', init: null,
    getOptions: getBoolSelectOptions,
    themeKey: 'codeHasLineNumbers' },
  
  border: {
    caption: 'Border', input: 'options', init: null,
    getOptions: getGetOptionsFnWithDefaults([
      { caption: 'Full', key: 'full' },
      { caption: 'None', key: 'none' },
      { caption: 'Sandwich', key: 'sandwich' }
    ]),
    themeKey: 'codeBorder'
  },

  borderColor: {
    caption: 'Border Color', input: 'color', init: null,
    nullable: true, themeKey: 'codeBorderColor',
    isHiddenFn: getValue => getValue('hasBorder') === false },

  lineWrapping: { caption: 'Word Wrap', input: 'checkbox', init: true },
  
  isHiddenOnPublish: {
    caption: 'Hidden on published', input: 'checkbox', init: false
  },  
  isReadOnly: {
    caption: 'Read only on published', input: 'checkbox', init: false
  },

  collapseMode: {
    caption: 'Collapse Behavior', input: 'options', init: 0,
    options: [
      { caption: 'Not Collapsible', key: 0 },
      { caption: 'Collapsible, Collapsed', key: 1 },
      { caption: 'Collapsible, Expanded', key: 2 }
    ]
  },

  filebarLayout: {
    caption: 'File Bar Layout', input: 'options', init: 0,
    options: [
      { caption: 'Auto', key: null },
      { caption: 'Topbar', key: 'hbar' },
      { caption: 'Float Inside', key: 'float' },
      { caption: 'Sidebar', key: 'vbar' }
    ]
  },

  verticalFileBarWidth: {
    caption: 'Vertical Filebar Width', input: 'number', init: 200,
  }
}

export const initialSettings = {};
for (let k in settingsFields) {
  initialSettings[k] = settingsFields[k].init;
}

export default settingsFields;