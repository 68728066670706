import { useEffect } from 'react';
import Loader from 'lib/staticPages/Loader';
import { getSizeString } from 'lib/tools.ts';
import { Button } from 'lib/inputs/buttons';
import Icon from 'lib/Icon';
import sendFeedback from 'lib/sendFeedback';
import { useControllerState } from 'lib/BaseController';
import { faHardDrive, faPaperclip } from '@fortawesome/free-solid-svg-icons';


export default function Upgrade({ controller }) {
  const
    state = useControllerState(controller.usage),
    { app } = controller,
    domain = controller.state.get('domain'),
    alert = state.get('alert'),
    tally = state.get('tally'),
    subscription = state.get('subscription'),
    plans = state.get('plans'),
    isLoaded = state.get('isLoaded') && domain;

  useEffect(() => { controller.usage.fetch() }, [controller]);

  return (
    <div className="py-16 px-8 w-full h-full overflow-auto">
      {
        alert ? (<div className="p-4 text-center">{alert}</div>) :
          !isLoaded || !domain  ?
            (<div className="p-4 text-center text-xl"><Loader /></div>) :
            (<UpgradePage tally={tally} domain={domain} subscription={subscription} plans={plans} app={app} />)
      }
    </div>
  )
}


function UpgradePage({ tally, domain, subscription, plans, app }) {
  const current = (subscription && subscription.currentPlan) || 'default';

  return (
    <div>
      <div className="title text-3xl text-center font-medium">Upgrade Your Workspace</div>

      <div className="text-center my-4">
        <div className="inline-block">
          <div className="font-medium text-xl">{domain.get('title')}</div>
          <div className="font-mono text-xs mb-1">{domain.get('slug')}</div>
        </div>
      </div>

      <div className="text-center">
        { Object.keys(plans).map(p => {
          const 
            plan = plans[p],
            storageTimes = Math.floor(plan.maxStorage / 256),
            uploadTimes = Math.floor(plan.maxUploadSize / 16);

          return (
            <div
              className="border border-rim rounded inline-block m-2 py-6 px-6 w-[25rem] align-top text-left relative shadow-lg bg-paper"
              key={p}>
              { p === current && (
                <div className="absolute top-1 right-2 text-xs text-pencil3 bg-wax2 p-1">
                  Current
                </div>
              )}

              <div className="font-medium text-2xl text-center">
                {plan.caption} Plan
              </div>

              <div className="my-3 py-3 border-rim2 border-b border-t min-h-[8rem]">
                <div className="flex mb-1">
                  <div className="flex-grow">
                    <span className="font-bold">
                      {getSizeString(plan.maxStorage * 1024 * 1024)}
                    </span> of storage
                  </div>

                  <div className="">
                    <Icon faIcon={faHardDrive} /> X {storageTimes}
                  </div>
                </div>

                <div className="flex mb-3">
                  <div className="flex-grow">
                    <span className="font-bold">
                      {getSizeString(plan.maxUploadSize * 1024 * 1024)}
                    </span> max upload file size
                  </div>

                  <div className="">
                    <Icon faIcon={faPaperclip}/> X {uploadTimes}
                  </div> 

                </div>

                { p !== 'default' && (
                  <div className="font-medium">
                    + higher limits
                  </div>
                )}
              </div>

              <div className="text-xl text-center">
                { plan.pricePerMonth ? (
                    <div><span className="text-marker">{plan.pricePerMonth}$ per month</span></div>) : (
                      <div>Free</div> )}
              </div>
            </div>
          )
        }) }

        <div className="mt-5">
          { current === 'default' ? (
            <Button
              onPress={() => sendFeedback({
                popupManager: app.popupManager,
                email: (app.auth.user && app.auth.user.email) || '',
                message: `Free upgrade my workspace "${domain.get('slug')}".`
              })}
              className="bg-paper">
              Request Upgrade to Basic for FREE!
            </Button>
          ) : (
            <div>
              You have upgraded to "{plans[current].caption}" plan.
            </div>
          )}
        </div>
      </div>
    </div>
  )
}