import BaseController from 'lib/BaseController';
import ThemeData from 'modules/theme/controllers/ThemeData';
import NavData from 'modules/theme/controllers/NavData';
import { toHuman } from 'lib/firebaseError';
import { fromJS } from 'immutable';
import Popups from './Popups';
import Access from './Access';
import Usage from './Usage';


export default class Domain extends BaseController {
  constructor({ app }) {
    super();
    this.app = app;
    this.popupManager = app.popupManager;
    this.popups = new Popups(this);
    this.unloadFns = [];
    this.state = this.state.merge({ domain: null, error: null, connected: false });
    this.themeData = new ThemeData();
    this.navData = new NavData();
    this.access = new Access(this);
    this.usage = new Usage(this);
  }

  load = async ({ id, slug, provider }) => {
    console.log('[', 'loading domain', slug, ']');
    if (this.slug === slug) {
      console.log('domain already loaded.', slug);
      return;
    } else if (this.slug) {
      this.unload();
    }
    this.id = id || (await provider.fetchPath(slug)).itemId;
    this.slug = slug;
    this.provider = provider;
    this.unloadFns.push(provider.onDomain(this.id, this.handleData, this.handleError));
  }

  handleData = (data) => {
    this.themeData.load(data.theme);
    this.navData.load(data.navLinks);
    this.setState({ domain: fromJS(data), connected: true });
  }

  handleError = (e) => {
    this.setState({ error: toHuman(e) })
  }

  updateDomain = async (data) => {
    this.setState({ domain: this.state.get('domain').merge(data) });
    await this.provider.updateDomain({ domainId: this.id, data });
  }

  unload = () => {
    console.log('unloading domain', this.slug);
    this.popupManager.closeSession(this.id);
    this.unloadFns.forEach(fn => fn());
    this.unloadFns = [];
    this.slug = null;
    this.setState({ connected: false, domain: null });
  }
}