import { useEffect, useState } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { EditorView } from '@codemirror/view';
import { indentUnit } from '@codemirror/language';
import { loadLanguage } from '@uiw/codemirror-extensions-langs';
import allThemes from 'lib/codemirror/themes';


export default function CodeMirrorInstance({ fileController, controller, isGrid, isHidden, themeKey }) {
  const
    { getSettingsValue } = controller,
    { state, setState } = fileController,
    height = isGrid ? '100%' : getSettingsValue('autoHeight') ? null : `${getSettingsValue('height')}px`,
    readOnly = controller.isReadOnly && getSettingsValue('isReadOnly'),
    extensions = [],
    languageExtension = loadLanguage(fileController.getSettingsValue('mode') || fileController.getSuggestedMode());
  
  if (languageExtension)
    extensions.push(languageExtension)

  if (getSettingsValue('lineWrapping')) {
    extensions.push(EditorView.lineWrapping)
  }
  if (getSettingsValue('tabMode') === '4s') {
    extensions.push(indentUnit.of('    '))
  }

  // Updater crew
  const [, setCurrentState] = useState(fileController.state);
  useEffect(() => fileController.onStateChange(() => setCurrentState(fileController.state)), [fileController]);

  return (
    <div className="codemirror-instance flex-grow" style={{display: isHidden ? 'none' : 'block'}}>
      <CodeMirror
        value={state.get('content') || ''}
        onChange={(value, viewUpdate) => setState({ content: value })}
        extensions={extensions}
        theme={allThemes[themeKey]}
        height={height}
        basicSetup={{
          lineNumbers: getSettingsValue('hasLineNumbers'),
          highlightActiveLineGutter: false,
          highlightActiveLine: false,
          foldGutter: false,
        }}
        readOnly={readOnly}
        onFocus={controller.activate}
        onCreateEditor={editorView => controller.setEditor(editorView, fileController.elementId)} />
    </div>
  );
}