import { Map } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
import { toHuman } from 'lib/firebaseError';
import View from './View';


class PopupController extends BasePopupController {
  static View = View;
  position = new Map({ width: 400 });

  constructor(args, manager) {
    super(args, manager);
    this.auth = args.auth;
    this.provider = args.provider;
    this.checkAvailable = this.provider.checkAvailable;
    this.article = args.article;
    this.version = args.version;
    this.folder = args.folder;
    this.domain = args.domain;
  }

  async renameSlug(slug) {
    var
      articleId = (
        (this.article && this.article.get('id')) ||
        (this.version && this.version.get('articleId'))
      ),
      versionId = this.version && this.version.get('id'),
      folderId = this.folder && this.folder.get('id'),
      domainId = this.domain && this.domain.get('id');

    this.setState({ loading: true, alert: '' });
    try {
      await this.provider.renameSlug({ slug, versionId, articleId, folderId, domainId });
      this.close();
    } catch (e) {
      console.error('e')
      this.setState({ alert: toHuman(e) });
    }
    this.setState({ loading: false });
  }
}

export default PopupController;