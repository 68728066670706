import { Map } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
import View from './View';

class Controller extends BasePopupController {
  static View = View;
  position = new Map({ width: 400 });

  async deleteVersion() {
    const { version, provider } = this.args;
    this.setState({ loading: true, alert: '' });
    try {
      await provider.deleteVersion({
        versionId: version.get('id'),
        articleId: version.get('articleId')
      });
      this.close();
    } catch(e) {
      console.error(e);
      this.setState({ alert: 'Error! ' + e.message });
    }
    this.setState({ loading: false });
  }
}

export default Controller;