import { useRef } from 'react';
import { Button } from 'react-aria-components';
import clsx from 'clsx';
import HeightResizer from 'modules/elements/lib/components/HeightResizer';
import MachineFiles from './MachineFiles';


export default function Optput({ controller, isGrid }) {
  const
    { state, getSettingsValue, setSettings } = controller,
    outputRef = useRef(),
    height = getSettingsValue('height'),
    autoHeight = getSettingsValue('autoHeight'),
    output = state.get('output'),
    savedOutput = state.get('savedOutput'),
    displayOutput = output === null ? savedOutput : output;

  return (
    <>
      <div
        className={clsx(
          'relative flex-grow min-h-10 font-code pt-2 pb-6',
          isGrid && 'h-full overflow-auto'
        )} ref={outputRef}>
        <div
          className="output font-code"
          style={
            isGrid ? {} : autoHeight ? { maxHeight: `${height}px` } : {height : `${height}px` }
          }>
          { displayOutput.map((o, i) => (
            <span key={i} className={'output-data ' + o.get('type')}>{o.get('data')}</span>
          ))}
        </div>

        { !isGrid && (
          <HeightResizer
            onChange={(dy) => {
              let
                height = getSettingsValue('height') + dy,
                range = controller.settingsFields.height.range;
              height = Math.max(Math.min(height, range[1]), range[0])
              setSettings('height', height);
            }} />
        )}

        <MachineFiles controller={controller} />
      </div>
      
      <RunningIndicator controller={controller} />
      <OutputButtons controller={controller} />      
    </>
  )
}

function RunningIndicator({ controller }) {
  const processIsActive = controller.state.get('processIsActive');

  return processIsActive && (
    <div className="text-xs absolute bottom-1 left-4 process-running text-green-600">
      running
    </div>
  )
}

function OutputButtons({ controller }) {
  const
    { state, isReadOnly } = controller,
    output = state.get('output'),
    savedOutput = state.get('savedOutput'),
    exceeded = controller.exceedsSizeLimit();

  return (
    <div className="text-xs absolute bottom-1 right-2 bg-paper">
      {!isReadOnly && (
        <OutputButton
          onPress={() => controller.saveOutput()}
          isDisabled={output === null || exceeded || output === savedOutput}>
          { exceeded ? (<span>Cannot save &gt; 200kb</span>) : <span>Save</span> }
        </OutputButton>
      )}
      
      <OutputButton
        onPress={() => controller.removeOutput()}
        isDisabled={!(
          output !== null && !(savedOutput.isEmpty() && output.isEmpty())
          && (savedOutput !== output)
        )}>
        Load
      </OutputButton>

      <OutputButton
        onPress={() => controller.clearOutput()}
        isDisabled={!(
          (output !== null && !output.isEmpty()) ||
          (output === null && !savedOutput.isEmpty()) )}>
        Clear
      </OutputButton>
    </div>
  )
}


function OutputButton(props) {
  return (
    <Button
      {...props}
      className={({ isDisabled }) => clsx(
        'uppercase p-1 mx-1',
        isDisabled && 'opacity-30'
      )} />);
}