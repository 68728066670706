const templates = [
  ['tools/base', 'Base', 'Base rock paper sand.'],
  ['port-a-diner/stepper-motors', 'Stepper'],
  ['blog', 'Blog'],
  ['travel', 'Travel Blog'],
  ['news', 'News'],
  ['research', 'Research Paper'],
  ['react', 'JavaScript React Module'],
  ['python', 'Python'],
];

export default templates;