import { Link } from 'react-router-dom';
import { ui } from 'lib/popup';
import { slugify } from 'lib/slugify';
import { useControllerState } from 'lib/BaseController';

import { Group, TextInput, CardSelect, Selection } from 'lib/inputs/basic';
import { Buttons, Button } from 'lib/inputs/buttons';
import { GridIcon, FileIcon, OpenInNewWindowIcon, MagicWandIcon } from '@radix-ui/react-icons';
// MagicWandIcon

import SlugCheckInput from 'lib/inputs/SlugCheckInput';
import templates from './templates.js';

import './style.css';


function Popup({ popup }) {
  let state = useControllerState(popup);

  return (
    <ui.Popup popup={popup} className="create-article-popup">
      <ui.Dialog>
        <ui.TitleBar>
          <ui.Title>
            Add a New Article
          </ui.Title>
          <ui.CloseButton />
        </ui.TitleBar>
        <ui.Body>
          {
            !state.get('created') ?
              <CreateArticle popup={popup} /> :
              <ArticleCreated popup={popup} />
          }
        </ui.Body>
      </ui.Dialog>
    </ui.Popup>
  )
}

function ArticleCreated({ popup }) {
  const { state } = popup;

  return (
    <div>
      <div className="text-lg text-center py-4">Article "{state.get('title')}" created!</div>
      <div className="text-right my-4">
        <Button href={`/editor/${state.get('parentPath')}/${state.get('slug')}`} autoFocus={true}>
          <OpenInNewWindowIcon className="inline-block" /> Open
        </Button>
      </div>
    </div>
  )
}

function CreateArticle({ popup }) {
  const
    { state } = popup,
    slug = state.get('slug'),
    valid = state.get('valid'),
    loading = state.get('loading');

  return (
    <form onSubmit={e => { e.preventDefault(); if (valid) popup.addArticle(); }}>
      <Group>
        <TextInput
          label="Article Title"
          value={state.get('title')}
          autoFocus={true}
          isDisabled={loading}
          onChange={v => {
            popup.setState({ title: v });
            if (state.get('autoSlug')) {
              popup.setState({ slug: slugify(v) });
            }
          }} />

        <SlugCheckInput
          label="Slug"
          value={state.get('slug')}
          onChange={v => popup.setState({ slug: v })}
          onValidChange={e => popup.setState({ valid: e })}
          checkFn={(val) => popup.checkAvailable(state.get('parentPath') + '\\' + val)} />

        <CardSelect
          label="Layout"
          value={state.get('layoutType')}
          itemClassName="text-center w-20 p-2 text-center text-sm outline-none"
          onChange={(layoutType) => popup.setState({ layoutType })}
          options={[
            { Card: () => <div><div className="mb-1">Grid</div><GridIcon className="m-auto" /></div>, value: 'grid' },
            { Card: () => <div><div className="mb-1">Scroll</div><FileIcon className="m-auto" /></div>, value: 'scroll' },
            { Card: () => <div><div className="mb-1">Template</div><MagicWandIcon className="m-auto" /></div>, value: 'template' },
          ]}/>

      { state.get('layoutType') === 'template' && (
        <>
          <Selection
            label="Select a template"
            value={state.get('templateIndex')}
            onChange={templateIndex => popup.setState({ templateIndex })}
            options={templates.map(([path, caption, description], i) => ({
              key: i,
              caption: (
                <div className="">
                  <div className="font-medium">{caption}</div>
                  <div className="text-sm">{description}</div>
                </div>
              )
            }))} />

          <div className="text-right">
            <Link
              className="text-sm underline"
              target="_blank"
              to={templates[state.get('templateIndex')][0]}>
              {/*'{templates[state.get('templateIndex')][1]}'*/}
                preview  <OpenInNewWindowIcon className="inline-block" />
              </Link>
          </div>
        </>
      )}
      </Group>

      <ui.Alert />

      <Buttons className="text-right mt-4">
        <Button isDisabled={loading || !slug || !valid} type="submit">
          Create A New Article
        </Button>
        <Button onPress={e => { popup.close() }}>Cancel</Button>
      </Buttons>
    </form>
  );
}


export default Popup;