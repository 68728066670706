import { DotFilledIcon, DotIcon, Cross2Icon } from '@radix-ui/react-icons';
import { Button, Link } from 'react-aria-components';
import clsx from 'clsx';


export default function FolderContent({ viewer }) {
  const { sections } = viewer.workspace;

  return (
    <>
      { sections.map((section, i) => section.items.length > 0 && (
        <div className="section mb-4 avoid-column-break" key={i}>
          { section.title && (
            <div className="font-medium px-2">
              {section.title}
            </div>
          ) }

          <div className="items">
            {section.items.map(item => (
              <FolderItem key={item.id} item={item} viewer={viewer} className="text-md" />
            ))}
          </div>
        </div>
      )) }
    </>
  )
}


export function FolderItem({ item, viewer, className }) {
  const
    { publishedVersionTitle, path, id, type } = item,
    isFolder = type === 'folder',
    isOpen = !!viewer.articles[id],
    isActive = id === viewer.state.get('itemId');

  return (
    <div className={clsx('flex items-top', className || 'text-sm')}>
      <div className="pt-2">
        { isOpen ? (
          <Button
            className={({ isDisabled }) => clsx(
              isDisabled && 'disabled',
              'group opacity-60',
              'close-button outline-none'
            )}
            isDisabled={isActive}
            onPress={(e) => viewer.closeArticle(id)}>
            <div className={clsx(!isActive && 'group-hover:hidden')}><DotFilledIcon /></div>
            <div className={clsx('hidden ', !isActive && 'group-hover:block')}><Cross2Icon /></div>
          </Button>
        ) : (
          <DotIcon />
        ) }
      </div>

      <Link
        className={clsx(
          'flex-grow py-1 px-1 outline-none hover:bg-wax2 !font-normal',
          isFolder ? '!text-marker2' : '!text-pencil'
        )}
        href={'/' + path.replace('\\', '/')}>
        { publishedVersionTitle }
      </Link>
    </div>
  );
}