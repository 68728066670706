const description = `
<h4 class="text-left">Note</h4>
<ul>
  <li><p class="text-left">Treat this workspace as sandbox.</p></li>
  <li><p class="text-left">You can access this workspace offline.</p></li>
  <li><p class="text-left">The data is saved on your local browser (IndexedDB), and
  will remain here until you explicitly delete the site data.</p></li>
  <li><p class="text-left">Anyone using this browser can access this workspace.</p></li>
  <li><p class="text-left">The features are limited.</p></li></ul>
`;

export function upgradeToVersion1(db) {
  const
    domains = db.createObjectStore('domains', { keyPath: 'id' }),
    paths = db.createObjectStore('paths', { keyPath: 'id' }),
    folders = db.createObjectStore('folders', { keyPath: 'id' });

  db.createObjectStore('articles', { keyPath: 'id' });
  db.createObjectStore('elements', { keyPath: 'id' });
  db.createObjectStore('uploads', { keyPath: 'id' });
  db.createObjectStore('storage', { keyPath: 'id' });

  domains.put({
    id: 'local',
    title: 'Offline Workspace',
    description: description,
    slug: 'local',
    cover640: 'dino.image', cover320: 'dino.image', cover160: 'dino.image'
  });
  paths.put({
    id: 'local',
    type: 'folder',
    itemId: 'local'
  });
  folders.put({
    id: 'local',
    domainId: 'local',
    path: 'local',
    title: 'Offline Home',
    parentId: null,
    settings: {},
    layout: { list: [] },
    thumbnails: {}
  });
}