import { Map } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
import View from './View';


class Controller extends BasePopupController {
  static View = View;
  position = new Map({ width: 350 });

  constructor(type, state, manager) {
    super(type, state, manager);
    this.checkUsedBy();
  }

  checkUsedBy() {
    this.args.getUploadUsedBy().then(docs => this.setState({ usedBy: docs }));
  }
    
  async deleteUpload() {
    this.setState({ loading: true, alert: '' });
    try {
      await this.args.deleteUpload();
      this.close();
    } catch(e) {
      console.error(e);
      this.setState({ alert: 'Error! ' + e.message });
    }
    this.setState({ loading: false });
  }
}

export default Controller;